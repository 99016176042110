// Dependencies
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
// Hooks & Helpers
import { useResetHeader } from "../hooks/useUpdateHeader"
// Components
import Layout from "../components/Layout"
import Image from "../components/Image"
import RichText from "../components/RichText"

const Practice = ({ data }) => {

	const { content } = data.allContentfulPractice.nodes[0]

	// Resets header colours back to black
	useResetHeader()

	return (
		<Layout headerTitle="Practice" title={`Practice | AFK`} hideLogo theme={{
			background: 'var(--blue-bg)',
			highlight: 'var(--blue)'
		}}>
			<div className="practice header-offset">
				<article className="outerx4 p4 has-indents has-list-columns has-break-indents">
					<RichText content={content} />
				</article>
			</div>
		</Layout>
	)
}

Practice.propTypes = {
  data: PropTypes.object.isRequired
}

export default Practice

export const PracticePageQuery = graphql`
  query PracticeQuery {
		allContentfulPractice(
			limit: 1
		) {
			nodes {
				content {
					raw
					references {
						... on Node {
							... on ContentfulGallery {
								...ContentfulGalleryFragment
							}
							... on ContentfulAsset {
								__typename # isRequired
								contentful_id # isRequired
								title
								description
								file {
									contentType
									url
								}
								image: gatsbyImageData(
									layout: FULL_WIDTH
									# formats: [JPG]
									quality: 80
								)
							}
							... on ContentfulQuote {
								...ContentfulQuoteFragment
							}
							... on ContentfulSiteCategory {
								__typename # isRequired
								contentful_id # isRequired
								slug
								colorHighlight
							}
						}
					}
				}
			}
		}
  }
`